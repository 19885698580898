body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');

@font-face {
  font-family: 'Source Sans Pro';
  src: url(./fonts/SourceSansPro/SourceSansPro-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url(./fonts/SourceSansPro/SourceSansPro-Bold.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

*::-webkit-scrollbar-track {
  background-color: none;
}

*::-webkit-scrollbar {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb  {
  background-color: #ffffff66;
  border-radius: 5px;
}

button {
  display: block;
  border: none;
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
}

button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

button:active {
  transform: scale(0.99);
}

.color-primary {
  color: #ffffff;
}

.color-secondary {
  color: #04515B
}

.bg-primary {
  color: #04515B
}

.bg-secondary {
  color: #FFFFFF;
}

.sans-serif {
  font-family: 'Open Sans', sans-serif;
}

.serif {
  font-family: 'Source Serif Pro', serif;
}

.section {
  font-family: 'Open Sans', sans-serif;
}

.section .title {
  font-size: 18px;
  font-weight: 700;
}

.section .content {
  font-size: 18px;
  font-weight: 400;
  padding-bottom: 32px;
}

.box .title {
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 8px;
}

.separator {
  border-bottom: 2px solid #FFFFFF;
  content: '';
}

.box .separator {
  width: 56px;
  padding-bottom: 8px;
}

.box .subtitle {
  font-size: 10px;
}

.box .content {
  font-size: 14px;
}

.content-wrapper {
  width: 75%;
  height: 100%;
  max-width: 1400px;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .content-wrapper {
    padding: 0 24px;
    width: 100%;
  }
}
